@import './settings.scss';

.MuiInputLabel-outlined.Mui-focused {
	color: $main-blue !important;
}

.MuiOutlinedInput-root.Mui-focused {
	.MuiOutlinedInput-notchedOutline {
		border-color: $main-blue !important;
	}
}
