@import "./reset.scss";
@import "./settings.scss";
@import "./custom-material.scss";

body {
	font-family: "Roboto";
	color: $black;
	padding: 0 15px;
}

.hidden {
	display: none;
}

.link {
	width: 100%;
	cursor: pointer;

	&-blue {
		color: $main-blue;
	}

	&-white {
		color: #fff;
	}

	&:hover {
		text-decoration: underline;
	}
}
.btn {
	border-radius: 6px;
	padding: 10px;
	width: 100%;
	text-align: center;
	font-size: 1em;
	color: #fff;
	cursor: pointer;

	&-blue {
		background-color: $main-blue;
		&:hover {
			opacity: 0.9;
		}
	}

	&-outline {
		border: $main-blue;
		color: $main-blue;
	}
}

.text-align-center {
	text-align: center;
}
